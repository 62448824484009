<template>
  <div class="library-wrapper">
    <div class="library-content">
      <div class="about-me-container">
        <about-me-library ref="about-me-library"/>
        <connect-other-libraries @on-connect-request="updateActiveClassrooms"
                                 :has-my-requests="hasMyRequests"
                                 @create-new-request="createMyNewRequest"/>
      </div>
      <div class="connections-container">
        <my-connection-requests ref="myConnectionRequests" @on-change-my-request="onChangeMyRequest"/>
      </div>
    </div>
  </div>
</template>

<script>
import AboutMeLibrary from '../Components/LibraryConnections/AboutMeLibrary'
import ConnectOtherLibraries from '../Components/LibraryConnections/ConnectOtherLibraries'
import MyConnectionRequests from '../Components/LibraryConnections/MyConnectionRequests'
import { MyClassRoomsMixins } from "@/modules/MyClassrooms/Mixins"

export default {
  name: "LibraryConnectionsPage",
  mixins: [MyClassRoomsMixins],
  components: {
    AboutMeLibrary,
    ConnectOtherLibraries,
    MyConnectionRequests
  },
  data () {
    return {
      sendingRequest: false,
      selectedInvitation: null,
      invitations: [],
      hasMyRequests: false
    }
  },
  methods: {
    createMyNewRequest () {
      this.$refs.myConnectionRequests.newRequest()
    },
    updateActiveClassrooms () {
      this.$refs['about-me-library'].getActiveClassrooms()
      this.getHeaderClassrooms()
    },
    onChangeMyRequest(hasRequest) {
      this.hasMyRequests = hasRequest
    }
  }
};
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"

.library-wrapper
  width 100%
  display flex
  justify-content center

  .library-content
    max-width 1300px
    display flex
    margin 0 15px
    margin-top 40px
    margin-bottom 20px
    min-height calc(100vh -(80px + 50px))
    width 100%

    .about-me-container
      width 70%
      display flex

    .connections-container
      width 30%
      display flex

    ::v-deep
      .title--main
        border-top-left-radius 13px
        border-top-right-radius 13px
        background-color color-55
        text-transform uppercase
        text-align center
        position absolute
        display flex
        justify-content center
        align-items center
        width 100%
        height 90px
        color color-1
        top 0
        left 0

@media(max-width 1000px)
  .library-wrapper
    .library-content
      flex-direction column

      .about-me-container
        width 100%
        margin-bottom 10px

      .connections-container
        width 100%

@media(max-width 600px)
  .library-wrapper
    .library-content
      .about-me-container
        width 100%
        flex-direction column
        margin-bottom 10px

      .connections-container
        width 100%
</style>
