<template>
  <modal id="group-name-review-modal"
         modal-class=""
         :hide-footer="true">
    <template #body>
      <h2 class="modal-title">Group Name Review</h2>
      <p class="subtitle" v-if="numberGroups"> Your class will include {{numberGroups}} groups. Please review and approve the following suggested names for each group, or replace them with your own names.</p>
    </template>
  </modal>
</template>

<script>
import { Modal } from '@/core/components/Modal'

export default {
  name: "ClassroomDetailGroupNameReview",
  components: {
    Modal
  },
  props: {
    studentsNumber: {
      type: Number,
      default: 0
    },
    numberGroups: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      sendingData: false
    }
  },
  computed: {
    studentsPerGroup() {
      return Math.round(this.studentsNumber / this.numberGroups)
    }
  },
  methods: {
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"
@import "~@/core/styles/mixins.styl"

.title
  font font-opensans-bold
  font-size 24px
  line-height 24px
  color color-3
  text-transform uppercase
  text-align left

.subtitle
  font-size 14px

.number-students-input
  margin-top 15px

@media (max-width 495px)
  .size-text-btn
    font-size 10px

</style>
<style lang="stylus">
#group-name-review-modal
  .modal-body
    .body
      padding-bottom 40px
</style>
