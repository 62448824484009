import {ClassroomService} from '@/core/Services'
import {
  FETCH_CLASSROOMS_START,
  FETCH_CLASSROOMS_DATA,
  FETCH_HEADER_CLASSROOMS_DATA
} from "@/modules/MyClassrooms/Storage/mutation-types";

export const actions = {
  getClassrooms({commit}, {filterBy = '', searchString = ''}) {
    commit(FETCH_CLASSROOMS_START)

    return ClassroomService.list({filter_by: filterBy, search_string: searchString}).then(response => {
      const classrooms = response.data;
      commit(FETCH_CLASSROOMS_DATA, classrooms)

      return classrooms
    })
  },
  getHeaderClassrooms({commit}) {
    return ClassroomService.list().then(response => {
      const classrooms = response.data
      commit(FETCH_HEADER_CLASSROOMS_DATA, classrooms)

      return classrooms
    })
  },
}

