<template>
  <modal id="number-students-modal" size="lg" :no-close-on-backdrop="true">
    <template #body>
      <b-overlay :show="sendingData" rounded="sm">
        <template #overlay>
          <div class="text-center">
            <b-spinner label="Spinning"/>
          </div>
        </template>
        <p class="modal-subtitle text-center">
          Please use the Chat on My Dashboard to coordinate the number of groups you will have in your classes with your global partner. You will have the same number of groups in both classes.
        </p>
        <b-form-group>
          <b-form-radio v-model="optionSelected" class="radio-button" name="some-radios" :value="1">OK - I've spoken with my partner and agreed on the number of groups. We will have
            <b-input type="number"
                     maxlength="5"
                     v-model="$v.form.numberStudents.$model"
                     placeholder="0"
                     ref="numberStudentsInput"
                     custom-class="common-input common-input--primary"
                     :disabled="disabledInput"
                     class="number-students-input"/>
            groups.
          </b-form-radio>
          <b-form-invalid-feedback :state="!$v.form.$dirty || !$v.$invalid">
            <ul class="error-content">
              <li class="error-text" v-if="!$v.form.numberStudents.required">Number of groups is required</li>
              <li class="error-text" v-if="!$v.form.numberStudents.between">Please enter a number between 1 and 15</li>
            </ul>
          </b-form-invalid-feedback>
          <b-form-radio v-model="optionSelected" class="radio-button" name="some-radios" :value="2">No, I need help with this. Please contact me.</b-form-radio>
          <b-form-radio v-model="optionSelected" class="radio-button" name="some-radios" :value="3">Skip this for now.</b-form-radio>
        </b-form-group>
      </b-overlay>
    </template>
    <template #footer>
      <b-button @click="saveNumberStudents" btn-text="Ok" :disabled="sendingData"/>
    </template>
  </modal>
</template>

<script>
import {Modal} from "@/core/components/Modal";
import BInput from "@/core/components/FormInput/Input";
import {Button} from "@/core/components/Button";
import {CourseMixins} from "@/modules/Classroom/Mixins";
import {ClassroomService} from "@/core/Services";
import {required, between} from 'vuelidate/lib/validators'

export default {
  name: "StudentsNumberModal",
  components: {
    Modal,
    BInput,
    'b-button': Button,
  },
  mixins: [CourseMixins],
  props: {
    classroom: Object,
  },
  data() {
    return {
      form: {
        numberStudents: '',
      },
      sendingData: false,
      optionSelected: 3,
      disabledInput: true
    }
  },
  validations: {
    form: {
      numberStudents: {
        required,
        between: between(1, 15)
      }
    }
  },
  methods: {
    saveNumberStudents () {
      if (this.optionSelected === 3) {
        this.$emit('change-tab')
        return
      }
      let data = {classroom_id: this.classroom.id}

      if (this.optionSelected === 1) {
        this.$v.$touch()
        if (this.$v.$invalid) {
          return
        }
        data.students_number = this.form.numberStudents
      }

      this.sendingData = true

      ClassroomService.saveStudentNumber(data).then((resp) => {
        if (resp.success) {
          const message = this.optionSelected === 2 ? 'Thank you for letting us know! Someone will be in contact with you soon.' : 'successful!'
          this.$bvModal.msgBoxOk(message, {
            size: 'sm',
            dialogClass: "modal-message-box",
            noCloseOnBackdrop: true,
            centered: true
          }).then(async () => {
            if (this.optionSelected === 2) {
              this.$emit('change-tab')
            } else {
              this.$bvModal.hide('number-students-modal')
              await this.getClassroomNoOverlay(this.classroom.id)
              this.$emit('save-number-students')
            }
          })
        } else {
          const errorMessage = resp.message ? resp.message : 'Error when saving student number.'
          this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`, {
            size: 'sm',
            dialogClass: 'modal-message-box',
            centered: true
          })
        }
      }).catch((res) => {
        const errorMessage = res.message ? res.message : 'Error reporting.'
          this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`, {
            size: 'sm',
            dialogClass: 'modal-message-box',
            centered: true
          })
      }).finally(() => this.sendingData = false)
    }
  },
  watch: {
    optionSelected (option) {
      if (option === 1) {
        this.disabledInput = false
        setTimeout(() => {
          this.$refs.numberStudentsInput?.$children[0]?.focus()
        }, 0)
      } else {
        this.disabledInput = true
        this.form.numberStudents = ''
        this.$v.$reset()
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.modal-subtitle
  margin-bottom 30px
  font-size 16px

.number-students-input
  margin 0
  width 60px
  display inline-block
  margin-top 5px

  ::v-deep
    .common-input
      padding 4px 10px
      margin-bottom 5px

@media (max-width 495px)
  .size-text-btn
    font-size 10px

</style>
<style lang="stylus">
#number-students-modal
  .modal-dialog
    max-width 680px !important

</style>
