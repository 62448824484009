export const getters = {
  getMyClassrooms: (state) => {
    return state.classrooms
  },
  getMyHeaderClassrooms: (state) => {
    return state.headerClassrooms
  },
  isLoadingClassrooms: (state) => {
    return state.loadingClassrooms
  },
}
