<template>
  <div class="classroom-detail-tabs-container">
    <b-tabs lazy content-class="tab-panel-detail" v-model="tabIndex" nav-class="nav-tabs-detail">
      <b-tab :key="index + '-tab-item'" v-for="(tab, index) in tabs"
             :active="!tab.disabled"
             :disabled="hideTab(tab.component)"
             @click="changeTab(tab.component)">
        <template #title>
          <div class="nav-link-item">
            <span :class="tab.icon"></span><span class="text">{{ tab.name }}</span>
          </div>
        </template>
        <div class="container-tab-body">
          <component v-bind:is="tab.component" v-bind="{...$props}" @change-tab="changeTabIndex"></component>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import {CourseMixins} from "@/modules/Classroom/Mixins"
import {TrackingActivityService} from '@/core/Services'
import {TrackingTypes} from "@/core/Utils"

import {
  ClassroomDetailTabMyStudents,
  ClassroomDetailTabMyTasks,
  ClassroomDetailTabSubmissions,
  ClassroomDetailTabMessageBoard,
  ClassroomDetailTabGlobalPartner
} from "./ClassroomDetailTabsGroup";

export default {
  name: "ClassroomDetailTabs",
  mixins: [CourseMixins],
  components: {
    ClassroomDetailTabMyStudents,
    ClassroomDetailTabMyTasks,
    ClassroomDetailTabSubmissions,
    ClassroomDetailTabMessageBoard,
    ClassroomDetailTabGlobalPartner
  },
  props: {
    tabs: {
      type: Array,
      required: true
    },
    classroom: {
      type: Object,
      required: function () {
        return {}
      }
    }
  },
  data() {
    return {
      tabIndex: 0
    }
  },
  methods: {
    hideTab(component) {
      switch (component) {
        case 'ClassroomDetailTabMyStudents':
          if (!this.classroom.course_id) return true
          return this.isWholeClassGroupType
        case 'ClassroomDetailTabMessageBoard':
          //hide ClassroomDetailTabMessageBoard when it is a whole classroom
          //return this.isWholeClassGroupType

          //hide ClassroomDetailTabMessageBoard always
          return true
      }
    },
    changeTab(tab) {
      let trackingType = null
      switch (tab) {
        case 'ClassroomDetailTabGlobalPartner':
          trackingType = TrackingTypes.dashboardTab
          break
        case 'ClassroomDetailTabMyStudents':
          trackingType = TrackingTypes.studentsTab
          break
        case 'ClassroomDetailTabMyTasks':
          trackingType = TrackingTypes.tasksTab
          break
        case 'ClassroomDetailTabSubmissions':
          trackingType = TrackingTypes.submissionsTab
          break
      }
      if (trackingType) {
        TrackingActivityService.trackingActivityTeachers(trackingType)
      }
    },
    changeTabIndex(tabIdx = 0) {
      this.tabIndex = tabIdx
    }
  },
  async mounted() {
    const currentTab = await this.tabs.find((tab) => !tab.disabled)
    this.changeTab(currentTab.component)
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"

.classroom-detail-tabs-container
  margin-top 13px
  background-color #F5F6FF
  padding 18px 8px 57px 8px

  ::v-deep
    .nav-tabs-detail
      justify-content space-between

      & > .nav-item
        display flex
        justify-content center
        align-items center

        @media (min-width: 500px)
          margin-left 5px


        .nav-link
          padding 0
          border 0

          &.disabled
            opacity 0.5
            display none
            pointer-events none

          &.active
            border: 0.5px solid color-23
            border-radius: 4px 4px 0 0
            padding-top 8px
            padding-bottom 8px

            .nav-link-item
              background-color transparent
              border 0
              border-radius 0

              span[class^="icon-"]
                &:before
                  color color-22
                  font-weight 700

              .text
                font-weight 700
                font-size 9px
                @media(min-width 475px)
                  font-size 12px

          &-item
            display flex
            flex-direction column
            justify-content center
            padding 5px 3px
            border 1px solid color-25
            background-color color-24
            border-radius 4px

            span
              display inline-block
              text-align center

            span[class^="icon-"]
              vertical-align middle
              font-size 1.2em

              &:before
                color color-22

            .text
              font font-opensans-regular
              vertical-align middle
              margin-left 0
              color color-8
              font-size 9px

            @media(min-width 475px)
              padding 5px
              .text
                font-size 12px

        &:first-child
          margin-left 0

    .tab-panel-detail
      box-shadow: 0 2px 3px #00000029;
      border: 1px solid color-26
      background-color color-1

@media (min-width: 768px)
  .classroom-detail-tabs-container
    padding 30px 15px 57px 15px

    ::v-deep
      .nav-tabs-detail
        justify-content: unset

        & > .nav-item
          margin-left 10px

          .nav-link
            &.active
              .nav-link-item
                .text
                  font-size 12px

            &-item
              padding 8px 10px

              .text
                margin-top 0
                font-size 12px
                letter-spacing 0.56px

@media (min-width: 1200px)
  .classroom-detail-tabs-container
    padding 30px 21px 57px 21px

    ::v-deep
      .nav-tabs-detail
        & > .nav-item
          .nav-link
            &.active
              .nav-link-item
                .text
                  font-size 16px

            &-item
              flex-direction row
              padding 13px 25px

              .text
                font-size 0.85em
                margin-left 6px

</style>
