<template>
  <modal modal-class="modal-student-response"
         :hide-footer="true"
         ref="submission-detail-modal"
         id="submission-detail-modal"
         @hide="closeSubmissionDetailModal">
    <template #body>
      <div class="container-detail-submission">
        <div class="container">
          <div class="detail-content">
            <div class="col">
              <div class="attribute">
                <div class="attribute-left">
                  <h2 class="modal-tittle">{{ profileLeftTitle }}</h2>
                  <div class="profile" v-if="profileLeft.id">
                    <div class="profile-left">
                      <b-avatar :src="profileLeft.avatar" size="3.1rem"/>
                    </div>
                    <div class="profile-right">
                      <div class="username">
                        {{ profileLeft.teacherName }}
                      </div>
                      <div class="username task-name">{{ profileLeft.taskName }}</div>
                      <div class="username">Posted On: {{
                          profileLeft.postedOn | dateMonthDayYearHour
                        }}
                      </div>
                    </div>
                  </div>
                  <p v-if="isCreate && !profileLeft.url" class="message">Waiting for your video</p>

                  <video-custom class="img-fluid"
                                ref="video-custom"
                                @on-video-play="playVideo"
                                :src="profileLeft.url"
                                v-if="profileLeft.url"
                                video-processed-message="VIDEO IS PROCESSING"
                                :video_processed="profileLeft.videoProcessed"/>

                  <upload-or-record-video @close-video-upload-modal="$bvModal.hide('submission-detail-modal')"
                                          @upload-video-success="uploadVideo"
                                          v-if="(isCreate && !profileLeft.url) || (!isCreate && profileRight.url && !profileLeft.url) && isCreate"
                                          :class="{'upload-container--response': !isCreate}"
                                          class="upload-container"
                                          :classroom-id="getClassroomData.id"
                                          :task-id="taskId"/>

                  <div v-if="isCreate && !profileLeft.url && !loading">
                    <h4 class="subtitle">PROMPT</h4>
                    <p class="prompt" v-html="createPrompt"></p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col col--response-container">
              <div class="attribute">
                <div class="attribute-left">
                  <h2 class="modal-tittle">{{ profileRightTitle }}</h2>
                  <div class="profile" v-if="profileRight.id">
                    <div class="profile-left">
                      <b-avatar :src="profileRight.avatar" size="3.1rem"/>
                    </div>

                    <div class="profile-right">
                      <div class="username">{{ profileRight.teacherName }}</div>
                      <div class="username">{{ profileLeft.taskName }}</div>
                      <div class="username">Posted On: {{ profileRight.postedOn | dateMonthDayYearHour }}</div>
                    </div>
                  </div>

                  <p v-if="isCreate && profileLeft.url && !profileRight.url" class="message">Your partner classroom has not yet submitted their response. Please communicate with them using the partner chat on the My Dashboard tab.</p>
                  <p v-if="!isCreate && !profileLeft.url" class="message">Partner has not yet submitted. You can contact your partner via chat on the My Dashboard tab.</p>

                  <video-custom class="img-fluid"
                                ref="video-custom-response"
                                @on-video-play="playVideo"
                                :src="profileRight.url"
                                v-if="profileRight.url"
                                video-processed-message="VIDEO IS PROCESSING"
                                :video_processed="profileRight.videoProcessed"/>

                  <upload-or-record-video @close-video-upload-modal="$bvModal.hide('submission-detail-modal')"
                                          @upload-video-success="uploadVideo"
                                          v-if="((isCreate && profileLeft.url) || (!isCreate && !profileRight.url && profileLeft.url)) && !isCreate"
                                          :class="{'upload-container--response': !isCreate}"
                                          class="upload-container"
                                          :classroom-id="getClassroomData.id"
                                          :task-id="taskId"/>

                  <div v-if="((isCreate && profileLeft.url) || (!isCreate && !profileRight.url && profileLeft.url)) && !isCreate && !loading">
                    <h4 class="subtitle">PROMPT</h4>
                    <p class="prompt" v-html="responsePrompt"></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <b-overlay no-wrap :show="loading"/>
      </div>
    </template>
  </modal>
</template>

<script>
import VideoCustom from "@/core/components/Video/VideoCustom";
import {CourseMixins} from "@/modules/Classroom/Mixins"
import {TrackingActivityService} from '@/core/Services'
import {TrackingTypes} from "@/core/Utils"
import {Modal} from "@/core/components/Modal"
import {ClassroomService} from "@/core/Services"
import UploadOrRecordVideo from './UploadOrRecordVideo.vue'

export default {
  name: "SubmissionDetailModal",
  mixins: [CourseMixins],
  components: {
    'video-custom': VideoCustom,
    Modal,
    UploadOrRecordVideo
  },
  data() {
    return {
      loading: false,
      profileLeft: {},
      profileRight: {},
      interval: null,
    }
  },
  props: {
    taskId: {
      type: Number
    },
    isCreate: {
      type: Boolean
    },
    createPrompt: {
      type: String
    },
    responsePrompt: {
      type: String
    },
  },
  mounted() {
  },
  methods: {
    async uploadVideo(fileUrl) {
      try {
        if (this.isCreate) {
          await ClassroomService.saveSubmissionVideo(this.getClassroomData.id, this.taskId, fileUrl)
        } else {
          await ClassroomService.saveResponseVideo(this.getClassroomData.id, this.taskId, fileUrl, this.profileLeft.id)
        }
        this.$emit('upload-video-success')
      } catch (error) {
        const errorMessage = error.message ? error.message : 'Error save video'

        this.$bvModal.msgBoxOk(`${errorMessage}, Please try again.`, {
          size: 'sm',
          dialogClass: "modal-message-box",
          noCloseOnBackdrop: true,
          centered: true
        })
      }

    },
    closeSubmissionDetailModal() {
      this.profileLeft = {}
      this.profileRight = {}
      clearInterval(this.interval)
    },
    async getSubmissionResponse() {
      try {
        this.loading = true
        let data
        let responses
        if (this.isCreate) {
          const responseData = await ClassroomService.getSubmissionByTaskId(this.getClassroomData.id, this.taskId)
          data = responseData?.data[0] || {}
          responses = data.id ? data?.responses[0] : {}
        } else {
          const responseData = await ClassroomService.getResponseByTaskId(this.getClassroomData.id, this.taskId)
          if (responseData.success) {
            data = responseData?.data[0] || {}
            responses = data?.responses ? data?.responses[0] : {}
          }
        }

        this.profileLeft = {
          videoProcessed: data?.video_processed,
          avatar: data.teacher?.thumbnail,
          teacherName: data.teacher?.full_name,
          taskName: data.class_task?.task_name,
          postedOn: data?.created_at,
          url: data?.url,
          id: data?.id
        }

        this.profileRight = {
          videoProcessed: responses?.video_processed,
          avatar: responses?.teacher?.thumbnail,
          teacherName: responses?.teacher?.full_name,
          postedOn: responses?.created_at,
          url: responses?.url,
          id: responses?.id
        }
        this.$nextTick(() => {
          if ((this.isCreate && this.profileLeft.url) || (!this.isCreate && this.profileRight.url)) {
            this.periodicLoadVid()
          }
        })
      } catch (error) {
        const errorMessage = error.message ? error.message : 'Error get submissions'

        this.$bvModal.msgBoxOk(`${errorMessage}, Please try again.`, {
          size: 'sm',
          dialogClass: "modal-message-box",
          centered: true
        })
      } finally {
        this.loading = false
      }
    },
    async openSubmissionDetailModal() {
      this.$bvModal.show('submission-detail-modal')
      await this.getSubmissionResponse()
    },
    periodicLoadVid() {
      const videoRef = this.isCreate ? 'video-custom' : 'video-custom-response'

      this.$nextTick(() => {
        const checkVideoLoaded = () => {
          const videoLoaded = this.$refs[videoRef].videoLoaded

          if (videoLoaded) {
            clearInterval(this.interval)
          } else {
            this.$refs[videoRef].loadVid()
          }
        }
        checkVideoLoaded() // Initial check
        this.interval = setInterval(checkVideoLoaded, 4000)
      })
    },
    pauseVideos() {
      this.$refs['video-custom'].pauseVid()
      this.$refs['video-custom-response'].pauseVid()
    },
    playVideo() {
      if (this.isSubmissionTab) {
        TrackingActivityService.trackingActivityTeachers(TrackingTypes.watchSubmissionVideo)
      } else {
        TrackingActivityService.trackingActivityTeachers(TrackingTypes.watchStudentVideo)
      }
    }
  },
  computed: {
    profileLeftTitle () {
      return 'ORIGINAL POST'
    },
    profileRightTitle () {
      return 'RESPONSE'
    }
  },
  watch: {
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"

.student-text-wrapper
  margin-top 38px
  height: 509px
  overflow-y auto

.container-detail-submission
  padding-top 30px
  position relative
  min-height 800px

  .container
    max-width 1400px
    padding 0

  .detail-content
    display flex
    width 100%
    @media (max-width 750px)
      flex-direction column

      .col--response-container
        margin-top 30px

  .profile
    display flex
    align-items center
    margin-bottom 38px

    &-left
      width 50px


    &-right
      text-align left
      padding-left 25px
      font font-opensans-bold
      font-size 0.7rem
      max-width 300px
      @media(max-width 800px)
        max-width 250px
      @media(max-width 400px)
        max-width 200px

      .username
        overflow hidden
        text-overflow ellipsis

      .task-name
        text-overflow ellipsis
        max-width 600px
        overflow hidden
        text-wrap nowrap

  .attribute
    display flex
    flex-direction column

    &-left
      flex-basis 300px

      .video-overlay
        width 100%
        height 395px
        border-radius 4px

    &-right
      text-align left

      &-course .title
        font font-opensans-bold
        font-size 1.25rem
        text-transform uppercase

      .subtitle
        font-size 0.875rem
        font font-opensans-bold
        margin-bottom 10px

      .posted
      .tags
      .description
        font font-opensans-regular
        font-size 0.75rem


      .description
        margin-bottom 25px


      .tags
        margin-bottom 10px

::v-deep
  .flag-inappropriate-menu
    padding-top 20px
    margin 0 auto

.message
  text-align center
  font font-opensans-semibold
  margin-bottom 20px

.subtitle
  font-size 20px
  margin-top 30px
  font-weight 500
  margin-bottom 10px

.prompt
  word-break break-word

@media (min-width: 992px) {
  .container-detail-submission {
    .profile {
      &-right {
        font-size 1rem
      }
    }

    .attribute {
      flex-direction row

      &-left {
        flex-basis 600px
        margin auto

        .video-overlay {
          width 100%
          height 725px
          border-radius 4px
        }
      }

      &-right {
        flex 1
      }
    }
  }
}

.upload-container
  position static
  height auto

  ::v-deep
    .video-overlay
      width 100%
      height 500px
      border-radius 4px
      @media (max-width: 992px)
        height 395px

.upload-container--response
  margin-top 133px

</style>
<style lang="stylus">
#submission-detail-modal
  .modal-dialog
    .close
      position relative
      z-index 1
</style>
