<template>
  <b-overlay :show="sendingData" rounded="sm">
    <template #overlay>
      <div class="text-center">
        <p id="cancel-label">Please wait...</p>
        <b-spinner label="Spinning"></b-spinner>
      </div>
    </template>
    <component @on-change-component="onChangeComponent" v-bind:is="currentComponent" @change-tab="(tabIndex) => $emit('change-tab', tabIndex)"
               v-bind="{...$props,...currentComponentParams}"
    ></component>
  </b-overlay>
</template>

<script>
import ClassroomDetailTabItemStudents from "./ClassroomDetailTabItemStudents"
import ClassroomDetailTabStudentDetail from "./ClassroomDetailTabStudentDetail"

export default {
  name: "ClassroomDetailTabMyStudents",
  components: {
    ClassroomDetailTabItemStudents,
    ClassroomDetailTabStudentDetail
  },
  data() {
    return {
      currentComponent: null,
      currentComponentParams: {},
      sendingData: false
    }
  },
  props: {
    classroom: Object,
  },
  mounted() {
    this.onChangeComponent({component: 'ClassroomDetailTabItemStudents'})
  },
  methods: {
    onChangeComponent(data) {
      this.currentComponent = data.component;
      this.currentComponentParams = data.params || {}
    },
  }
}
</script>

<style lang="stylus" scoped>


</style>
