<template>
  <div class="about-me">
    <h2 class="title title--main">About Me</h2>
    <b-avatar class="avatar"
              size="10rem"
              :src="user.avatar"/>
    <p class="subtitle">{{user.active_classrooms_count}} Active Classrooms</p>
    <p class="subtitle">{{user.active_librarian_connection_requests_count}} Active Connection Requests</p>
    <p class="subtitle">{{user.videos_count}} Lifetime videos exchanged</p>
    <p class="subtitle">Renew by: {{formattedDate(user.membership_expiration)}}</p>
    <p class="subtitle subtitle--description">{{user.about_info}}</p>
    <h2 class="title title--classroom">Active Classrooms</h2>
    <p class="message" v-if="!classroomsList.length">No active connections yet.</p>
    <ul class="classrooms-container">
      <li class="classroom-name" v-for="(classroom, index) in classroomsList" :key="`${classroom.id}-${index}`" @click="goToClassroomPage(classroom.id)">{{classroom.name}}</li>
      <li class="classroom-name" v-if="classroomsList.length" @click="goToMyClassroomsPage()">Show all...</li>
    </ul>
    <b-overlay no-wrap :show="loading"/>
  </div>
</template>

<script>
import {LibrarianService} from '@/core/Services'
import {UtilsMixins} from "@/core/Mixins"
import moment from 'moment'

export default {
  name: "AboutMeLibrary",
  mixins: [UtilsMixins],
  components: {
  },
  mounted() {
    this.getActiveClassrooms()
  },
  data() {
    return {
      loading: false,
      classroomsList: []
    }
  },
  methods: {
    formattedDate(date) {
      return date ? moment(date).format('DD MMM YYYY') : date
    },
    getActiveClassrooms() {
      this.loading = true
      LibrarianService.getActiveClassrooms().then((res) => {
        if (res.success) {
            this.classroomsList = res.data
        } else {
          const errorMessage = res.message ? res.message : 'Error loading actives classrooms.'
          this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`, {
            size: 'sm',
            dialogClass: 'modal-message-box',
            centered: true
          })
        }
      }).finally(() => {
        this.loading = false
      })
    }
  }
};
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"
@import "~@/core/styles/mixins.styl"

.about-me
  font font-opensans-regular
  font-size 20px
  margin-bottom 30px !important
  border 2px solid color-4
  border-radius 15px
  height 100%
  padding 10px
  padding-top 100px
  width 30%
  position relative
  @media(max-width 600px)
    width 100%

  .title
    font font-opensans-bold
    font-size 22px

  .subtitle
    font font-opensans-semibold
    padding-left 10px
    margin 0
    margin-bottom 5px

  .subtitle--description
    margin-top 15px

  .avatar
    margin 0 auto
    display block
    margin-top 20px
    margin-bottom 20px
    @media(min-width 500px) and (max-width 600px)
      margin-left 10px
      margin-right 0

  .title--classroom
    margin-top 15px

  .message
    text-align center
    font font-opensans-semibold
    margin 0
    padding 10px

  .classrooms-container
    margin 0
    padding 0
    padding-left 10px
    list-style none

    .classroom-name
      color color-link
      text-decoration underline
      cursor pointer
      font font-opensans-semibold
      margin-bottom 12px

</style>
