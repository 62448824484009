<template>
  <header v-if="!isSecurityPage">
    <div class="d-flex align-items-center justify-content-between h-100">
      <div>
        <div class="logo">
          <img src="~@/core/assets/luv-logo-wide.png" class="wide-logo" alt="LUV" v-if="!isPlayersTrustClassroom">
          <img src="~@/core/assets/PlayersTrust_H_LBG_RGB.png" class="wide-logo wide-logo--players" alt="LUV" v-if="(isPlayersTrustTeacher && (isPlayersTrustClassroom && isClassroomPage)) || (isPlayersTrustTeacher && !isClassroomPage)">
          <img src="~@/core/assets/luv-logo-small.png" class="small-logo" alt="LUV" v-if="!isPlayersTrustClassroom">
          <img src="~@/core/assets/PlayersTrust_Icon_RGB.png" class="small-logo small-logo--players" alt="LUV" v-if="(isPlayersTrustTeacher && (isPlayersTrustClassroom && isClassroomPage)) || (isPlayersTrustTeacher && !isClassroomPage)">
        </div>
      </div>
      <div class="flex-grow-0 flex-shrink-0 d-flex align-items-center">
        <b-navbar class="navbar-profile pt-1 pb-1">
          <b-navbar-nav class="nav-item-connections">
            <!-- <b-nav-item @click="goToPartnerConnectionsPage">
              <span class="nav-item-connections-text">My Connections</span>
              <span class="nav-item-connections-icon icon-global"></span>
            </b-nav-item> -->
            <b-nav-item @click="goToLibraryConnectionsPage" v-if="isLibrarian">
              <span class="nav-item-connections-text">Connections</span>
              <span class="nav-item-connections-icon icon-global"></span>
            </b-nav-item>

            <b-nav-item-dropdown v-if="(isLibrarian && headerClassrooms.length) || !isLibrarian">
              <template #button-content>
                {{ isLibrarian ? 'My Library' : 'My Classrooms' }}
              </template>
              <b-dropdown-item @click="createClassroomExchange" v-if="!isLibrarian">
                Create Classroom Exchange
              </b-dropdown-item>
              <b-dropdown-item v-if="headerClassrooms && headerClassrooms.length > 0" @click="goToMyClassroomsPage()">
                See All
              </b-dropdown-item>
              <b-dropdown-item v-for="classroom in headerClassrooms" :key="classroom.id"
                               @click="goToClassroomPage(classroom.id)">
                {{ classroom.name }}
              </b-dropdown-item>
            </b-nav-item-dropdown>

            <b-nav-item :href="teacherResourceUrl"
                        target="_blank"
                        class="nav-item-resource">
              <span class="nav-item-resource__label">Resources</span>
            </b-nav-item>

            <b-nav-item @click="goToNotificationsPage" class="nav-item-notification">
              <span class="nav-item-notification-text"
                    :class="{'nav-item-notification-text--point': !getReadNotification}">Notifications</span>
              <span v-if="!getReadNotification"
                    class="nav-item-notification-icon icon-notification-dark"
                    :class="{'nav-item-notification-text--point': !getReadNotification}"/>
              <span v-else
                    class="nav-item-notification-icon icon-notification"
                    :class="{'nav-item-notification-text--point': !getReadNotification}"/>
            </b-nav-item>

            <b-nav-item-dropdown right>
              <template #button-content>
                <b-avatar size="3.1rem" :src="user.avatar"></b-avatar>
              </template>
              <router-link :to="{name: 'TeacherProfilePage'}" custom v-slot="{ href }">
                <b-dropdown-item :href="href">Edit Profile</b-dropdown-item>
              </router-link>
              <router-link :to="{name: 'MyAccount'}" custom v-slot="{ href }">
                <b-dropdown-item :href="href">My Account</b-dropdown-item>
              </router-link>
              <router-link :to="{name: 'CourseLibraryPage'}" custom v-slot="{ href }">
                <b-dropdown-item :href="href">Course Library</b-dropdown-item>
              </router-link>
              <b-dropdown-item :href="teacherResourceUrl"
                               target="_blank"
                               class="dropdown-item-resource">
                Resources
              </b-dropdown-item>
              <router-link :to="{name: 'AccountChangePassword'}" custom v-slot="{ href }">
                <b-dropdown-item :href="href">PASSWORD</b-dropdown-item>
              </router-link>
              <router-link :to="{name: 'LogOutPage'}" custom v-slot="{ href }">
                <b-dropdown-item :href="href">LOG OUT</b-dropdown-item>
              </router-link>
            </b-nav-item-dropdown>
          </b-navbar-nav>
        </b-navbar>
      </div>
    </div>
    <alert-message />
    <modal id="unsigned-agreement-modal" @hide="isCreateClassroomPage && goToMyClassroomsPage()">
      <template #body>
        <div class="unsigned-agreement-subtitle">We have not yet received a signed Data Privacy Agreement for your school. Level Up Village requires that all schools participating in our virtual exchange programs complete a data privacy agreement with LUV. Please send this link to a school administrator for an electronic signature. Once we receive the signed form, you will be able to participate in our virtual exchange programs</div>
        <a @click.prevent="copyLink"
           :href="formQuery"
           class="unsigned-agreement-link"
           v-b-tooltip="{ title: 'Copied!', trigger:'click'}">{{formQuery}}</a>
      </template>
      <template #footer>
        <b-button @click="copyLink"
                  v-b-tooltip="{ title: 'Copied!', disabled: false, trigger:'click'}"
                  btn-text="Copy link"
                  class="button-copy"/>
      </template>
    </modal>
  </header>
</template>

<script>
import {NotificationsService} from "@/core/Services";
import AlertMessage from "@/core/components/Alert/AlertMessage"
import { MyClassRoomsMixins } from "@/modules/MyClassrooms/Mixins"
import {UserMixins} from "@/modules/Classroom/Mixins"
import moment from 'moment'
import { Button } from "@/core/components/Button"
import { Modal } from "@/core/components/Modal"
import { MiscService } from '@/core/Services'
import {UtilsMixins, userTypeMixins} from "@/core/Mixins"
import {SubjectTypes, Utils} from "@/core/Utils"

export default {
  name: "HeaderApp",
  mixins: [UserMixins, MyClassRoomsMixins, UtilsMixins, userTypeMixins],
  components: {
    AlertMessage,
    Modal,
    'b-button': Button,
  },
  props: {
    isSecurityPage: {
      type: Boolean,
      default: false,
      required: false
    },
  },
  data() {
    return {
      interval: null,
      userTeacher: JSON.parse(localStorage.getItem('user')),
      teacherResourceUrl: 'https://levelupvillage.com/resources/?ppwp_ac=eyJwYXNzd29yZCI6IjYzMWFjMDkzNjIyNTUifQ==',
      schoolDpaSigned: false,
      isClassroomPage: false
    }
  },
  mounted() {
    if (!this.isSecurityPage) {
      if (navigator.onLine) {
        this.getNotifications().then(() => {
          this.interval = setInterval(() => {
            if (navigator.onLine) {
              this.getNotifications()
            }
          }, 20000);
        })
      }

      this.getHeaderClassrooms()

      if (this.userTeacher?.school?.id) {
        MiscService.checkSchoolDpaSigned(this.userTeacher?.school?.parent_organization_id, this.userTeacher?.school?.id).then((response) => {
          this.schoolDpaSigned = response?.data
          if (this.isCreateClassroomPage && !this.schoolDpaSigned) {
            this.$bvModal.show('unsigned-agreement-modal')
          }
        }).catch(e => console.error(e))
      }
    }
  },
  computed: {
    isCreateClassroomPage() {
      return this.$root?._route?.name === 'CreateClassroomPage'
    },
    formQuery () {
      let query = `?&school_id=${this.userTeacher?.school?.id}&teacher_id=${this.userTeacher?.id}`
      return `${process.env.VUE_APP_LUV_PARENT_ORGANIZATION_AGREEMENT_FORM_URL}${query}`
    },
    teacherHasPlayersTrustCourse() {
      return !!this.headerClassrooms?.find((classroom) => classroom?.course?.subject_id === SubjectTypes.playersTrust.id)
    },
    isPlayersTrustTeacher() {
      return Utils.isPlayerTrustApp() || this.teacherHasPlayersTrustCourse
    }
  },
  methods: {
    copyLink () {
      navigator.clipboard.writeText(this.formQuery);
      setTimeout(() => {
        this.$root.$emit('bv::hide::tooltip')
      }, 1000)
    },
    createClassroomExchange() {
      if (!this.schoolDpaSigned) {
        return this.$bvModal.show('unsigned-agreement-modal')
      }
      this.goToCreateClassroomPage()
    },
    getNotifications() {
      return NotificationsService.list().then((response) => {
        const notifications = response.data.notifications

        const lastNotification = JSON.parse(localStorage["lastNotification" + "user" + this.userTeacher.id] || '{}')
        const unreadNotification = notifications.find(notification => notification.status === 'unread')
        const setNotification = (read) => {

          localStorage["lastNotification" + "user" + this.userTeacher.id] = JSON.stringify({
            createdAt: notifications[0]?.created_at,
            read: read
          })
        }

        if (lastNotification.createdAt) {
          const newNotification = moment(notifications[0]?.created_at).isAfter(lastNotification.createdAt)

          if (newNotification) {
            setNotification(false)
            this.readNotification(false)
          } else {
            localStorage["lastNotification" + "user" + this.userTeacher.id] = JSON.stringify(lastNotification)
            this.readNotification(lastNotification.read)
          }
          if (!unreadNotification) {
            setNotification(true)
            this.readNotification(true)
          }
        } else {
          if (unreadNotification) {
            setNotification(false)
            this.readNotification(false)
          } else {
            setNotification(true)
            this.readNotification(true)
          }
        }
      })
    },
  },
  watch: {
    '$route.name': {
      handler: function (nameComponent) {
        if (nameComponent !== 'ClassroomPage') {
          this.setPlayersTrust(false)
        }
        if (nameComponent === 'ClassroomPage') {
          this.isClassroomPage = true
        }
      },
      deep: true,
      immediate: true
    }
  },
  destroyed() {
    clearInterval(this.interval)
    this.interval = null
  }
};
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"

header
  height 78px
  padding-right 20px
  padding-left 10px
  border-bottom 2px solid color-2

  .logo
    display flex

    .wide-logo
      width 240px
      display none
      margin-right 20px

    .wide-logo--players
      display none

    .small-logo
      width 65px
      margin-right 5px

    .small-logo--players
      width 45px

    @media(min-width 440px)
      .small-logo
        width 85px
        margin-right 20px

      .small-logo--players
        width 55px

    @media(min-width 620px)
      .wide-logo .wide-logo--players
        display none

      .small-logo, .small-logo--players
        display block

    @media(min-width 1070px)
      .wide-logo, .wide-logo--players
        display block

      .small-logo, .small-logo--players
        display none

  .navbar-profile
    padding 0

    ::v-deep
      .navbar-nav
        & > .nav-item
          align-items center
          display flex

          &:nth-child(2), &:nth-child(3)
            .nav-link
              font font-opensans-regular
              font-size 12px
              @media (min-width 475px)
                font-size 14px

          .nav-link
            font font-opensans-bold
            font-size 12px
            color color-3
            @media (min-width 475px)
              font-size 14px

          .dropdown-menu
            background-color color-1
            box-shadow: 0 3px 6px #00000029
            border: 2px solid color-16
            border-radius: 7px
            padding 0
            margin 0

            & > li
              border-bottom 2px solid color-16

              &:last-child
                border 0

              a
                color #262626
                font font-opensans-bold
                font-size 14px
                padding-top 13px
                padding-bottom 13px
                text-transform uppercase
                text-overflow ellipsis
                white-space nowrap
                overflow hidden
                max-width 285px

                &:focus
                &:hover
                &:active
                  background-color white


.nav-item
  &-notification
    &-text--point
      &:after
        content " "
        display block
        position absolute
        z-index 1
        width 8px
        height 8px
        background-color #fe472a
        border-radius 50%

    &-text
      position relative

      &:after
        right -3px
        top 4px

      @media (max-width: 768px)
        display none

    &-icon
      display none
      position relative
      @media (max-width: 768px)
        display block
        font-size 1rem

      &:after
        right 0
        top -2px

  &-connections
    &-text
      @media (max-width: 768px)
        display none

    &-icon
      display none
      @media (max-width: 768px)
        display block
        font-size 1rem

  &-resource
    @media (max-width: 540px)
      display none !important

.unsigned-agreement-subtitle
  text-align center
  margin-top 10px

.unsigned-agreement-link
  word-break break-word
  text-align center
  margin-top 10px
  display block

.dropdown-item-resource
  display none
  @media (max-width: 540px)
    display block

@media (min-width: 768px)
  header
    padding-right 50px
    padding-left 37px

    .navbar-profile
      ::v-deep
        .navbar-nav
          & > .nav-item
            &:nth-child(2), &:nth-child(3)
              .nav-link
                font font-opensans-regular
                font-size 18px

            .nav-link
              font-size 18px

            .dropdown-menu
              & > li
                a
                  font-size 16px
                  padding-top 16px
                  padding-bottom 16px
                  max-width 400px

</style>
